import React, { useState } from "react";
import emailjs from "emailjs-com";
import "../App.css";

const EmailForm = () => {
  const [gamename, setGameName] = useState("");
  const [usercode, setUserCode] = useState("");
  const [nickname, setUserName] = useState("");
  const [checkStatus, setCheckStatus] = useState(false);
  const gameChangeHandle = (e) => {
    setGameName(e.target.value);
  };

  const codeChangeHandle = (e) => {
    setUserCode(e.target.value);
  };

  const nameChangeHandle = (e) => {
    setUserName(e.target.value);
  };
  const checkChangeHandle = (e) => {
    setCheckStatus(e.target.checked);
  };

  const userInfoSubmit = (e) => {
    e.preventDefault();
    const data = { gamename, usercode, nickname, checkStatus };
    if (gamename === "") {
      alert("게임이름을 정확히 입력하세요.");
      return;
    }
    if (usercode === "") {
      alert("코드를 정확히 입력하세요.");
      return;
    }
    if (nickname === "") {
      alert("닉네임을 정확히 입력하세요.");
      return;
    }
    if (checkStatus === false) {
      alert("동의체크를 해주세요.");
      return;
    }
    if ((usercode.length > 9) & (usercode.length < 16) || checkStatus === true) {
      // 타운어벤져스
      emailjs.send("service_0bftvfs", "template_vj9vsen", data, "user_CsoOtp2f3zhXPnLAClU5V").then(
        () => {
          e.target.reset();
          setCheckStatus(false);
          alert("등록의 완료되었습니다.");
        },
        (error) => {
          console.log("FAILED...", error);
        }
      );
      // 재배소년
      // emailjs.send("service_2pwnx2r", "template_9pwobza", data, "user_CsoOtp2f3zhXPnLAClU5V").then(
      //   () => {
      //     e.target.reset();
      //     setCheckStatus(false);
      //     alert("등록의 완료되었습니다.");
      //   },
      //   (error) => {
      //     console.log("FAILED...", error);
      //   }
      // );
    } else {
      return;
    }
  };

  return (
    <>
      <div className="content">
        <div style={{ display: "block", margin: "auto" }}>
          <form onSubmit={userInfoSubmit}>
            <font className="pointColor">
              <h2>Facebook 정보 삭제 요청(Facebook data deletion)</h2>
            </font>
            <div className="WarningBox"></div>
            <div className="Input_Form">
              ▼ 게임(Game)
              <br />
              <input
                className="Input_Box"
                onChange={gameChangeHandle}
                id="GAME"
                type="text"
                name="gamename"
                maxlength="20"
                placeholder="Game Name"
              />
              <br />
              <br />
              ▼ 고객번호(User Code)
              <br />
              <input
                className="Input_Box"
                onChange={codeChangeHandle}
                id="CODE"
                type="text"
                name="usercode"
                maxlength="20"
                placeholder="User Code"
              />
              <br />
              <br />
              ▼ 닉네임(Nick Name)
              <br />
              <div id="NICK_ERR" className="error"></div>
              <input
                className="Input_Box"
                onChange={nameChangeHandle}
                // value={nickname}
                id="NICK"
                type="text"
                name="nickname"
                maxlength="20"
                placeholder="User Name"
              />
              <br />
              <br />
            </div>
            <table>
              <tr>
                <td>
                  <h3>※ 주의사항</h3>
                  - Facebook 데이터 삭제 요청은 이용약관의 회원탈퇴에 해당합니다.
                  <br />
                  - 신청 시 Facebook 계정과 연동된 모든 게임정보가 삭제되며, 복구가 불가능합니다.
                  <br />
                  <h3>※ Caution</h3>
                  - The Facebook data deletion request corresponds to the withdrawal of membership of the Terms and
                  Conditions.
                  <br />
                  - Upon application, all game information linked to the Facebook account will be deleted and cannot be
                  restored.
                  <br />
                </td>
              </tr>
            </table>
            <br />
            <div>
              <input
                type="checkbox"
                id="AGREE"
                name="permission_agree"
                value={checkStatus}
                onChange={checkChangeHandle}
              />
              동의합니다.(Agree)
            </div>
            <hr />
            <div style={{ textAlign: "center" }}>
              {usercode === "" || nickname === "" || checkStatus === false ? (
                <>
                  <input type="submit" value="확인" className="sendbutton" />
                  {/* <button type="submit" align="center" style={{ backgroundColor: "lightgray" }}>
                  확인
                </button> */}
                </>
              ) : (
                <>
                  <input type="submit" value="확인" className="sendbutton" style={{ backgroundColor: "lightblue" }} />
                  {/* <button type="submit" align="center" onClick={userInfoSubmit} style={{ backgroundColor: "lightblue" }}>
                  확인
                </button> */}
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EmailForm;
